import { ReactNode } from "react";
import Header from "./Header";
import Footer from "../components/Footer";
import "../assets/vendor/tiny-slider/dist/tiny-slider.css";
import "../assets/vendor/simplebar/dist/simplebar.min.css";
import "../assets/css/theme.min.css";
import "../assets/css/nft.css";
type Props = {
  children?: ReactNode;
};
export default function Layout({ children }: Props) {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}

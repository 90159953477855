import React, { useState, useEffect, useRef, useContext } from "react";
import { formatEther } from "@ethersproject/units";
import ConnectButton from "../components/ConnectButton";
import AccountModal from "../components/AccountModal";
import AdminLayout from "../components/AdminLayout";
import UserLayout from "../components/UserLayout";
import TitleLayout from "../components/TitleLayout";
import AlertLayout from "../components/AlertLayout";
import AppContext from "../context/AppContex";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Link
} from "react-router-dom";
import {
  useDisclosure,
  Flex,
  Text,
  Container,
  Stack,
  Box,
  Heading,
  Button,
  Image,
  keyframes,
  Icon,
  IconButton,
  createIcon,
  IconProps,
  useColorModeValue,
  Spacer,
  usePrefersReducedMotion
} from "@chakra-ui/react";
import start from "../assets/start.png"
import ball from "../assets/ball.png"
import oro from "../assets/gold.png"
import plata from "../assets/silver.png"
import bronce from "../assets/bronze.png"
import { BigNumber, FixedNumber } from "ethers";

export default function Mint() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { state, addUSD, addRLC } = useContext(AppContext);
  const { usd, rlc } = state;
  const [priceUSD, setpriceUSD] = useState(0);
  const [blink, setBlink] = useState(true);
  const statesMembership = [
    { title: 'VIP Gold', color: '#C9A569', supply: 100, price: 10000 },
    { title: 'VIP Silver', color: '#C0C0C0', supply: 400, price: 5000 },
    { title: 'VIP Bronze', color: '#4c4208', supply: 2500, price: 1000 }];
  const [membership, setMembership] = useState(statesMembership[0]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: function (index) {
      console.log(index)
      setMembership(statesMembership[index]);
    },
  };

  const onButtonPress = () => {
    console.log('Presiono')
  }

  return (
    <>
      <Flex justifyContent={'flex-end'} mt={2}>
        <ConnectButton />
      </Flex>
      <Flex alignItems='center' justifyContent='center' flexDirection='column' textAlign='center' display={{ base: 'none', md: 'block' }}>
        <Text fontSize='6rem' fontWeight={600} color={'red.600'}>RED <span style={{ color: '#c9a569' }}>VIP</span> </Text>
      </Flex>
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 8, md: 10 }}
        direction={{ base: 'column', md: 'row' }}>
        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={{ base: 'full', md: '2xl' }}
        >
          <Box
            position={'relative'}
            height={{ base: '170px', md: '400px' }}
            width={'80%'}>
            <Slider {...settings}  >
              <Flex justifyContent={'center'} alignItems={'center'}>
                <Image
                  alt={'Red VIP Balls'}
                  src={oro}
                  w={'50%'}
                  m={'0 auto'}
                  h={'auto'}
                  borderRadius={'10px'}
                />
              </Flex>
              <Flex>  <Image
                alt={'Red VIP Balls'}
                h={'auto'}
                w={'50%'}
                src={plata}
                m={'0 auto'}
                borderRadius={'10px'}
              />
              </Flex>
              <Flex>  <Image
                alt={'Red VIP Balls'}
                h={'auto'}
                w={'50%'}
                src={bronce}
                m={'0 auto'}
                borderRadius={'10px'}
              />
              </Flex>
            </Slider>
          </Box>

        </Flex>
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
            <Text
              as={'span'}
              color={membership.color}
              position={'relative'}>
              {membership.title}
            </Text>

          </Heading>
          <Flex justifyContent={'space-between'} w={{ base: 'full', md: '50%' }}>
            <Text color={'gray.500'} fontSize={'lg'}>
              0 / {membership.supply}
            </Text>
            <Text color={'gray.500'} fontSize={'lg'}>
              Price:   {(parseFloat(membership.price))
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")} RLC
            </Text>
          </Flex>
          <Text color={'gray.500'}>
            <strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
          </Text>
          <Stack
            justifyContent='center'
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: 'column', sm: 'row' }}>
            <Link to="/mint" >
              <Button
                rounded={'full'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
                bg={'#C9A569'}
                _hover={{ bg: '#7c653e' }}
                onClick={() => onButtonPress()}
              >
                Mint
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Stack>
      <Image
        alt={'Red VIP Start'}
        position={'absolute'}
        left={'90%'}
        bottom={'10%'}
        w={'5%'}
        h={'auto'}
        src={start}
      />
      <Image
        alt={'Red VIP Balls'}
        position={'absolute'}
        left={'10%'}
        top={'10%'}
        w={'3%'}
        h={'auto'}
        src={ball}
      />
      <AlertLayout />
      <AccountModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}

import React, { useState, useEffect, useContext } from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  CloseButton,
  Flex,
} from "@chakra-ui/react";
import AppContext from "../context/AppContex";
export default function AlertLayout() {
  const { state } = useContext(AppContext);
  const { alert } = state;
  const [alertComponent, setalertComponent] = useState(false);

  useEffect(() => {
    console.log(alert);
    if (alert.visible) {
      setalertComponent(true);
      setTimeout(() => {
        setalertComponent(false);
      }, 100000);
    }
  }, [alert]);

  const closedAlert = () => {
    setalertComponent(false);
  };
  return (
    <>
      {alertComponent && (
        <div className="alert alert-primary">Hola {alert?.message}</div>
      )}
    </>
  );
}

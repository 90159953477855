import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import BgImg from "../assets/img/nft/home/hero-bg.png";
import Globe from "../assets/img/nft/catalog/globe-nft-002.png";
import Wallet from "../assets/img/nft/features/wallet.svg";
import AddSVG from "../assets/img/nft/features/add.svg";
import Image from "../assets/img/nft/features/image.svg";
import ShoppingCart from "../assets/img/nft/features/shopping-cart.svg";
import MobielApp from "../assets/img/nft/mobile-app.png";
import { tns } from "../../node_modules/tiny-slider/src/tiny-slider";
import ConnectButton from "../components/ConnectButton";
import { useWallet } from "../hooks/useWallet";
import AppContext from "../context/AppContex";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Home(props: any) {
  const [activeSlide] = useState(true);
  const { account, keyAddress } = useWallet();
  useEffect(() => {
    tns({
      container: ".tns-carousel-inner",
      items: 4,
      gutter: 24,
      slideBy: 1,
      autoplay: false,
      autoplayButtonOutput: false,
      autoplayTimeout: 5000,
      speed: 900,
      loop: true,
      mouseDrag: true,
      nav: true,
      navPosition: "bottom",
      controls: false,
      controlsText: [
        '<i class="bi bi-arrow-left"></i>',
        '<i class="bi bi-arrow-right"></i>',
      ],
      responsive: {
        320: {
          items: 1,
        },
        480: {
          items: 1.3,
        },
        576: {
          items: 1.5,
        },
        768: {
          items: 2.4,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 3.5,
        },
        1400: {
          items: 4,
        },
      },
    });
  }, [activeSlide]);

  useEffect(() => {
    if (account) {
      console.log("Si hay account");
      RegisterAddress();
    }
  }, [account]);

  const RegisterAddress = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ address: account }),
    };
    fetch("https://www.redcoin.ai/whitelistadd", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          toast("Registered successfully");
        } else if (data.status === "completed") {
          console.log("Wallet already registered");
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <section
        className="mb-lg-2 bg-faded-accent bg-size-cover"
        style={{
          paddingTop: "80px",
          backgroundImage: `url(${BgImg})`,
        }}
      >
        <div className="container py-4">
          <div className="row align-items-center justify-content-center gy-3 py-3 text-lg-start text-center">
            <div className="col-lg-5 col-md-8 col-sm-10">
              <h1 className="mb-4 pb-lg-2">Change the way content is valued</h1>
              <p className="mb-lg-5 mb-4 fs-lg">
                RedNFT marketplace is a global content subscription service and
                NFT marketplace, powered by fundamentals of Web3 and Blockchain
                technology, which allows fans become active participants in the
                work they love by offering them a membership and the access
                created by NFTs. <br />
                <br />
                You give them access to one of a kind NFTs and exclusive
                content, community, and insight into your creative process. In
                exchange, you get the freedom to do your best work, and the
                stability you need to build an independent creative career and a
                community. <br />
                <br />
                Content creators can earn money from users who subscribe to
                their content or purchase their NFTs—the "Redfans". Our NFT
                marketplace allows content creators to receive funding directly
                from their fans on a monthly basis as well as one-time NFT
                purchase or the royalties generated from NFTs sold on second
                market and much more.
              </p>
            </div>
            <div className="col-lg-6 col-md-8 offset-lg-1 col-sm-10">
              <div className="mb-4 mx-n2" id="register">
                <div>
                  <div className="px-2">
                    <img className="rounded-3" src={Globe} alt="Product" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="container mb-lg-2">
          <div className="row m-5 text-center">
            <h2>
              <span style={{ color: "red" }}>Red</span>NFT Marketplace Structure
            </h2>
          </div>
        </div>
        <div className="row text-center row-cols-1 row-cols-md-3">
          <div className="col col-lg-4 d-flex align-items-stretch">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-body">
                <h3 className="card-title pricing-card-title">
                  <span style={{ color: "red" }}>RED</span>LINERS
                </h3>
                <p>
                  Create an account on REDLINE BLOCKCHAIN and share your content
                  under any category -exclusively, privately, or publicly!
                </p>
              </div>
            </div>
          </div>
          <div className="col col-lg-4 d-flex align-items-stretch">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-body">
                <h3 className="card-title pricing-card-title">
                  <span style={{ color: "red" }}>RED</span>VIEWERS
                </h3>
                <p>
                  Anyone can view public content. Only REDFANS are allowed to
                  view private or exclusive content.
                </p>
              </div>
            </div>
          </div>
          <div className="col col-lg-4 d-flex align-items-stretch">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-body">
                <h3 className="card-title pricing-card-title">
                  <span style={{ color: "red" }}>RED</span>FANS
                </h3>
                <p>
                  REDFANS may buy, rent, subscribe to exclusive content based on
                  the value set by creators. This is paid back 100% to
                  REDLINERS.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mt-5">
        <div className="card py-5 border-0 shadow">
          <div className="card-body py-md-4 py-3 px-4 text-center">
            <h3 className="mb-3">Whitelist by Connecting your Red Wallet!</h3>
            <p className="mb-4 pb-2">
              Connect your RedWallet now to be registered as a Redliner. <br />
              Soon you'll be able to list your NFTs and interact with others on
              our global marketpalce.
            </p>
            <p>
              <strong>
                Whitelist today by clicking the Connect to a wallet button!
              </strong>
            </p>
            <br />
            <br />
            <div style={{ padding: "60.25% 0 0 0", position: "relative" }}>
              <iframe
                src="https://player.vimeo.com/video/727972164?h=cc054680e3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                allow="autoplay; fullscreen; picture-in-picture"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="RedNFT Marketplace Welcome Video"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </section>
      <section className="container py-lg-5 py-4">
        <h2 className="mb-4 pb-md-3 pb-2">Begin your NFT journey with us!</h2>
        <div className="tns-carousel mb-4">
          <div className="tns-carousel-inner">
            <div>
              <img className="mb-4" src={Wallet} width="60" alt="Icon" />
              <h4 className="mb-2 fs-lg text-body">Download the RedWallet</h4>
              <p className="mb-0 fs-sm text-muted">
                If you don't have a RedWallet yet, download your RedWallet for
                your device now.{" "}
                <a
                  href="https://redwallet.redlineblockchain.com/"
                  target="_blank"
                  className="link-accent"
                  rel="noreferrer"
                >
                  Click here{" "}
                </a>
                for the link for your Android, iOS, or Google Chrome Browser
                device.{" "}
              </p>
            </div>
            <div>
              <img className="mb-4" src={AddSVG} width="60" alt="Icon" />
              <h4 className="mb-2 fs-lg text-body">Complete the eKYC</h4>
              <p className="mb-0 fs-sm text-muted">
                After you download the RedWallet, complete the eKYC so you can
                access your RedWallet address and other features. This is very
                important.
              </p>
            </div>
            <div>
              <img className="mb-4" src={Image} width="60" alt="Icon" />
              <h4 className="mb-2 fs-lg text-body">Register Your Account</h4>
              <p className="mb-0 fs-sm text-muted">
                Connect and register your account by clicking the "Connect
                Wallet" button on this page. This will link your RedWallet to
                your RedNFT Marketplace account.{" "}
              </p>
            </div>
            <div>
              <img className="mb-4" src={ShoppingCart} width="60" alt="Icon" />
              <h4 className="mb-2 fs-lg text-body">Standby to sale</h4>
              <p className="mb-0 fs-sm text-muted">
                We'll announce the official opening of RedNFT Marketplace soon!
                You'll be able to showcase your NFTs and apply various
                subscription or purchase models to your account.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container py-lg-5 py-4">
        <div className="card border-0 position-relative overflow-hidden">
          <span
            className="d-block w-100 h-100 position-absolute top-0 start-0 zindex-1 bg-accent opacity-15"
            style={{
              background:
                "conic-gradient(from 207.95deg at 50% 50%, #885CFF -57.31deg, #FF6B9B 44.14deg, #DC86FF 78.21deg, #885CFF 123.94deg, #DC86FF 186.36deg, #FF6B9B 249.2deg, #885CFF 302.69deg, #FF6B9B 404.14deg",
            }}
          ></span>

          <div className="card-body row py-0 px-sm-0 position-relative zindex-5">
            <div className="col-lg-9 col-sm-10 offset-sm-1 d-flex flex-md-row flex-column align-items-md-end text-md-start text-center">
              <div
                className="my-5 me-md-5 me-auto ms-md-0 ms-auto py-md-4"
                style={{ maxWidth: "495px" }}
              >
                <h3 className="h2 mb-3">
                  <span className="text-accent">The Best Wallet</span> for NFT
                  Enthusiasts & Content Creators!
                </h3>
                <p className="mb-sm-4 mb-3">
                  If you're an NFT enthusiast, or a content creator, or are
                  looking to get more familiar with NFTs, consider downloading
                  the RedWallet App! The RedWallet app is your key to RedNFT
                  Marketplace and Redline Blockchain ecosystem.
                </p>
                <div className="mx-n1">
                  <a
                    className="btn-market btn-apple mt-3 mx-1"
                    href="https://apps.apple.com/dk/app/red-wallet/id1607470682"
                  >
                    <span className="btn-market-subtitle">Download on the</span>
                    <span className="btn-market-title">App Store</span>
                  </a>
                  <a
                    className="btn-market btn-google mt-3 mx-1"
                    href="https://play.google.com/store/apps/details?id=com.redlineblockchain.wallet&hl=en_US&gl=US"
                  >
                    <span className="btn-market-subtitle">Download on the</span>
                    <span className="btn-market-title">Google Play</span>
                  </a>
                </div>
              </div>
              <div className="flex-shrink-0 ms-md-auto">
                <img
                  src={MobielApp}
                  alt="Illustration"
                  style={{
                    borderTopLeftRadius: "28px",
                    borderTopRightRadius: "28px",
                    boxShadow: "16px 16px 24px -7px rgba(0, 0, 0, .3)",
                  }}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="pt-4">
        <section className="container">
          <div className="card py-5 border-0 shadow">
            <div className="card-body py-md-4 py-3 px-4 text-center">
              <h3 className="mb-3">Ready to start?</h3>
              <p className="mb-4 pb-2">
                Connect your RedWallet now to be registered as a Redliner.{" "}
                <br />
                Soon you'll be able to list your NFTs and interact with others
                on our global marketpalce.
              </p>
              <p>
                <strong>
                  Whitelist today by clicking the Connect to a wallet button!
                </strong>
              </p>
            </div>
          </div>
        </section>
      </div>

      <ToastContainer position="bottom-right" newestOnTop />
    </>
  );
}

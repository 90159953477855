import React, { useState, useEffect, useRef, useContext } from "react";
import { utils, ethers, BigNumber, FixedNumber } from "ethers";
import AlertLayout from "../components/AlertLayout";
import { useWallet } from "../hooks/useWallet";
import AppContext from "../context/AppContex";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  useDisclosure,
  Flex,
  Text,
  Stack,
  Box,
  Button,
  Image,
  Checkbox,
} from "@chakra-ui/react";
import bronce from "../assets/bronze.png"
import Header from "../components/Header";
import Disclaimer from "../components/Disclaimer";

export default function Bronze() {
  const { isOpen, onOpen, onClose } = useDisclosure();


  const { account, keyAddress } = useWallet();
  const [buttonAccount, setbuttonAccount] = useState(true);
  const [balance, setBalance] = useState(0);
  const { state, mintNFT, approvedRLC, addAlert, getBalanceNFT } =
    useContext(AppContext);


  const [check, setCheck] = useState(false);
  const statesMembership = [
    { title: 'VIP Bronze', color: '#4c4208', supply: 2500, price: 1000 }];
  const [membership, setMembership] = useState(statesMembership[0]);

  const disclaimerOpen = () => {
    setCheck(!check);
    onOpen();
  }

  useEffect(() => {
    getBalanceWallet()
    if (account) {
      setbuttonAccount(false);
    } else {
      setbuttonAccount(true);
    }
    console.log(account);
  }, [account]);


  const getBalanceWallet = async () => {
    console.log(account)
    if (account !== "") {
      const etherBalance = await getBalanceNFT("Bronze");
      console.log(etherBalance);
      setBalance(etherBalance);
    }
  };


  useEffect(() => {
    if (keyAddress) {
      setbuttonAccount(false);
      haddleApproved();
    }
  }, [keyAddress]);

  const haddleApproved = async () => {
    var trx = "";
    setbuttonAccount(true);
    addAlert({
      status: "info",
      message: "Transaction in progress",
      visible: true,
    });
    trx = await approvedRLC(account, keyAddress, "Bronze")
      .then((response) => {
        console.log(response)
        haddleMint()
      })
      .catch((error) => {
        console.log(error);
        addAlert({
          status: "error",
          message: "Insufficient funds for gas * price + value",
          visible: true,
        });
        const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.reload();
        }, 50000);
      });
  };

  const haddleMint = async () => {
    var trx = "";
    setbuttonAccount(true);
    addAlert({
      status: "info",
      message: "Transaction in progress",
      visible: true,
    });
    trx = await mintNFT(account, keyAddress, "Bronze")
      .then((response) => {
        addAlert({
          status: "success",
          message: "Transaction Completed: " + response.transactionHash,
          visible: true,
        });
        const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.reload();
        }, 100000);
      })
      .catch((error) => {
        console.log(error);
        addAlert({
          status: "error",
          message: "Insufficient funds for gas MATIC or  1000 RLC",
          visible: true,
        });
        const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.reload();
        }, 5000);
      });
  };

  return (
    <>
      <Header wallet={true} />
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 8, md: 10 }}
        direction={{ base: 'column', md: 'row' }}>
        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={{ base: 'full', md: '2xl' }}
        >
          <Box
            position={'relative'}
            width={'80%'}>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <Image
                alt={'Red VIP Balls'}
                src={bronce}
                w={'100%'}
                m={'0 auto'}
                h={'auto'}
              />
            </Flex>
          </Box>
        </Flex>
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Flex alignItems='start' justifyContent='center' flexDirection='column' textAlign='center'>
            <Text fontSize={{ base: '44px', md: "52px" }} fontWeight={800} color={'#ffffff'}>REDVIP BRONZE</Text>
          </Flex>
          <Flex justifyContent={'space-between'} w={{ base: 'full', md: '50%' }}>
            <Text color={'white'} fontSize={'lg'}>
              {balance} / {membership.supply}
            </Text>
            <Text color={'white'} fontSize={'lg'}>
              Price:   {(parseFloat(membership.price))
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")} RLC
            </Text>
          </Flex>
          <Text color={'white'}>
            The REDVIP BRONZE access card has a fixed supply of 2,500 with an original price of 1,000.00 RLC. Include, Standard Admission token to all of our online events and special discount rate for all of our in-person events and Gift tokens.
            <br />
            <br />
            Access summary: Access tokens, admission tokens, and gift tokens.
            <br />
            <br />
            Benefits include but are not limited to:
            <br />
            <br />
            <ul style={{ marginLeft: '30px' }}>
              <li>Standard admission to our online events  </li>
              <li>Special discount rate for all of our in-person events </li>
              <li>RedVIP business forum </li>
              <li>Gift tokens. </li>
            </ul>
          </Text>
          <Stack
            justifyContent='start'
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: 'column', sm: 'column' }}>
            <Checkbox colorScheme='red' color={'#ffffff'} onChange={disclaimerOpen}>Please read disclaimer before minting</Checkbox>
            <Button
              rounded={'2px'}
              px={6}
              fontWeight={600}
              color={'#ffffff'}
              bg={'#ff0000'}
              id="redline_signBrowser"
              disabled={buttonAccount || !check}
              _hover={{ bg: '#ff0000' }}>
              Mint
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <AlertLayout />
      <Disclaimer isOpen={isOpen} onClose={onClose} />
    </>
  );
}

import { useState } from "react";
import initialState from "../initialState";
import { utils, ethers } from "ethers";
import NFTVipABI from "../abi/NFTVip.json";
import { erc20Abi } from "../abi/abi";
import Web3 from "web3";


const { REACT_APP_NEWTORK_MAINET, REACT_APP_RLC,  REACT_APP_CONTRACT_NFT, REACT_APP_CONTRACT_NFT_GOLD, REACT_APP_CONTRACT_NFT_SILVER  } = process.env;



const webProvider = new Web3(
  new Web3.providers.HttpProvider(
    REACT_APP_NEWTORK_MAINET //process.env.REACT_APP_INFURA
  )
); // eslint-disable-line



const useInitialState = () => {
  const [state, setState] = useState(initialState);

  const addUSD = (payload) => {
    /*setState({
      ...state,
      usd: payload,
    });*/
    setState(prevState => {
      return {
        ...prevState,
        usd: payload,
      };
    });
  };

  const addRLC = (payload) => {
    console.log(state)
    /* setState({
       ...state,
       rlc: payload,
     });*/
    setState(prevState => {
      return {
        ...prevState,
        rlc: payload,
      };
    });
  };

  const addAlert = (payload) => {
    setState({
      ...state,
      alert: payload,
    });
  };

  const CallContractRLC = () => {
    const provider = new ethers.providers.JsonRpcProvider(
      REACT_APP_NEWTORK_MAINET
    );
    const addr = "0xF31777D123C86d5A239BF7253502c01B7bEC9738";
    let accounts = provider.listAccounts();
    console.log(accounts)
    const signer = provider.getSigner();
    console.log(signer)
    const priceFeed = new ethers.Contract(addr, NFTVipABI, provider);
    return priceFeed;
  };

  const getBalance = async (accountAddress) => {
    if (!accountAddress) return;
    let balanceTemp = await webProvider.eth.getBalance(accountAddress);
    balanceTemp =
      balanceTemp !== 0 ? webProvider.utils.fromWei(balanceTemp, "ether") : 0;
    return balanceTemp;
  };

  const getBalanceRLC = async (accountAddress) => {
    let tokenAddress = REACT_APP_RLC
    let walletAddress = accountAddress;
    let contract = new webProvider.eth.Contract(erc20Abi, tokenAddress);
    let balance = await contract.methods
      .balanceOf(walletAddress)
      .call(function (error, result) {
        return result;
      });
    balance = balance !== 0 ? webProvider.utils.fromWei(balance, "ether") : 0;
    return balance;
  };


  const getBalanceNFT = async (category) => {
    console.log('Categoria de Balance NFT ' + category);
    let tokenAddress = "";
    if (category === "Gold") {
      tokenAddress = REACT_APP_CONTRACT_NFT_GOLD;
    } else if(category === "Silver"){
      tokenAddress = REACT_APP_CONTRACT_NFT_SILVER;
    } else if(category === "Bronze"){
      tokenAddress = REACT_APP_CONTRACT_NFT;
    }
    
    let contract = new webProvider.eth.Contract(NFTVipABI, tokenAddress);
    let balance = await contract.methods
      .totalSupply()
      .call(function (error, result) {
        return result;
      });
    return balance;
  };

  const approvedRLC = async (accountAddress, privateKey, category) => {
    console.log('Categoria de approvedRLC  ' + category);
    const gasPrice = await webProvider.eth.getGasPrice();;
    const count = await webProvider.eth.getTransactionCount(accountAddress);
    const tokenContractRLC = REACT_APP_RLC;
    const nonce = webProvider.utils.toHex(count);
    const contract = new webProvider.eth.Contract(erc20Abi, tokenContractRLC);
    let tokenAddress = "";
    if (category === "Gold") {
      tokenAddress = REACT_APP_CONTRACT_NFT_GOLD;
    } else if(category === "Silver"){
      tokenAddress = REACT_APP_CONTRACT_NFT_SILVER;
    } else if(category === "Bronze"){
      tokenAddress = REACT_APP_CONTRACT_NFT;
    }
    
    const data = contract.methods.approve(tokenAddress, webProvider.utils.toWei("10000", "ether")).encodeABI();


    const txData = {
      gasPrice: gasPrice,
      gas: 1000000,
      nonce: nonce,
      data,
      from: accountAddress,
      to: tokenContractRLC
    };

    const account = webProvider.eth.accounts.privateKeyToAccount("0x" + privateKey);
    webProvider.eth.accounts.wallet.add(account);
    console.log(txData);

    const sentTx = await webProvider.eth.accounts
      .signTransaction(txData, privateKey)
      .then((signedTx) =>
        webProvider.eth.sendSignedTransaction(signedTx.rawTransaction)
      );

    return sentTx;
  };



  const mintNFT = async (address, privateKey, category) => {
    const gasPrice = await webProvider.eth.getGasPrice();;
    const count = await webProvider.eth.getTransactionCount(address);
    let tokenAddress = "";
    if (category === "Gold") {
      tokenAddress = REACT_APP_CONTRACT_NFT_GOLD;
    } else if(category === "Silver"){
      tokenAddress = REACT_APP_CONTRACT_NFT_SILVER;
    } else if(category === "Bronze"){
      tokenAddress = REACT_APP_CONTRACT_NFT;
    }
    console.log(tokenAddress)

    const nonce = webProvider.utils.toHex(count);
    const contract = new webProvider.eth.Contract(NFTVipABI, tokenAddress);


    const data = contract.methods.mint().encodeABI();
    const txData = {
      gasPrice: gasPrice,
      gas: 1000000,
      nonce: nonce,
      value: 0,
      data,
      from: address,
      to: tokenAddress,
    };

    const account = webProvider.eth.accounts.privateKeyToAccount("0x" + privateKey);
    webProvider.eth.accounts.wallet.add(account);
    console.log(txData);

    const sentTx = await webProvider.eth.accounts
      .signTransaction(txData, privateKey)
      .then((signedTx) =>
        webProvider.eth.sendSignedTransaction(signedTx.rawTransaction)
      );

    return sentTx;
  };


  return {
    CallContractRLC,
    addUSD,
    addAlert,
    addRLC,
    getBalance,
    getBalanceRLC,
    mintNFT,
    approvedRLC,
    getBalanceNFT,
    state,
  };
};

export default useInitialState;

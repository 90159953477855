import React, { useState, useEffect, useContext } from "react";
import Header from "../components/Header";
import {
    Flex,
    Text,
    Stack,
    Box,
    Image,
    Link,
    Heading
} from "@chakra-ui/react";
import oro from "../assets/gold.png"
import plata from "../assets/silver.png"
import bronce from "../assets/bronze.png"
export default function Membership(props: any) {
    return (
        <>
            <Header />
            <Flex alignItems='center' justifyContent='center' flexDirection='column' textAlign='center' >
                <Heading
                    lineHeight={1.1}
                    fontWeight={600}
                    mb={3}
                    textAlign={{ base: 'center', md: "justify" }}
                    fontSize={{ base: '3xl', sm: '4xl', lg: '4xl' }}>
                    <Text
                        as={'span'}
                        color={'#ffffff'}
                        position={'relative'}
                    >
                        Mint Your Access Now!
                    </Text>
                </Heading>
                <Text color={'#ffffff'} textAlign={{ base: 'center', md: "center" }}>
                    Please select from one of the access levels below to  learn more about their benefits and price.  <br /> Select a card to mint your access now!
                </Text>
            </Flex>
            <Stack
                align={'center'}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 8, md: 10 }}
                direction={{ base: 'column', md: 'row' }}>
                <Flex
                    flex={1}
                    justify={'center'}
                    align={'center'}
                    position={'relative'}
                    w={{ base: 'full', md: '2xl' }}
                >
                    <Box
                        position={'relative'}
                    >
                        <Link href={'/gold'} >
                            <Image
                                alt={'VIP GOLD'}
                                fit={'cover'}
                                align={'center'}
                                w={{ base: '70%', sm: '80%', md: '100%', lg: '100%', xl: '100%' }}
                                m={'0 auto'}
                                h={'auto'}
                                src={oro}
                                opacity={{ base: '1', md: '1' }}
                                _hover={{ opacity: 1 }}
                            />
                        </Link>
                    </Box>
                </Flex>
                <Flex
                    flex={1}
                    justify={'center'}
                    align={'center'}
                    position={'relative'}
                    w={{ base: 'full', md: '2xl' }}
                >
                    <Box
                        position={'relative'}
                    >
                        <Link href={'/silver'} >
                            <Image
                                alt={'VIP SILVER'}
                                fit={'cover'}
                                align={'center'}
                                w={{ base: '70%', sm: '80%', md: '100%', lg: '100%', xl: '100%' }}
                                m={'0 auto'}
                                h={'auto'}
                                src={plata}
                                opacity={{ base: '1', md: '1' }}
                                _hover={{ opacity: 1 }}
                            />
                        </Link>
                    </Box>
                </Flex>
                <Flex
                    flex={1}
                    justify={'center'}
                    align={'center'}
                    position={'relative'}
                    w={{ base: 'full', md: '2xl' }}
                >
                    <Box
                        position={'relative'}
                    >
                        <Link href={'/bronze'} >
                            <Image
                                alt={'VIP BRONZE'}
                                fit={'cover'}
                                align={'center'}
                                w={{ base: '70%', sm: '80%', md: '100%', lg: '100%', xl: '100%' }}
                                m={'0 auto'}
                                h={'auto'}
                                src={bronce}
                                opacity={{ base: '1', md: '1' }}
                                _hover={{ opacity: 1 }}
                            />
                        </Link>
                    </Box>
                </Flex>
            </Stack>
        </>
    );
}
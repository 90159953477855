import { Button, Box, Text } from "@chakra-ui/react";
//import { useEthers, useEtherBalance } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";
import Identicon from "./Identicon";
import { useWallet } from "../hooks/useWallet";
import { useEffect, useState, useContext } from "react";
import AppContext from "../context/AppContex";
type Props = {
  handleOpenModal: any;
};
export default function ConnectButton() {
  const { account } = useWallet();
  const [balance, setBalance] = useState("");
  const { getBalanceRLC }: any = useContext(AppContext);

  useEffect(() => {
    getBalanceWallet();
  }, [account]);

  const getBalanceWallet = async () => {
    console.log(account);
    if (account !== "") {
      //const etherBalance = await getBalanceRLC(account);
      //console.log(etherBalance);
      //setBalance(etherBalance);
    }
  };

  const disconnectWallet = () => {
    localStorage.removeItem("accountAddress");
    window.location.reload();
  };

  return account ? (
    <Button
      bg="lightgreen"
      color="white"
      borderRadius="0.4375rem"
      borderColor="none"
      fontSize="0.8125rem"
      border="1px solid transparent"
      _hover={{
        border: "1px",
        borderStyle: "solid",
      }}
      padding=".425rem 1rem"
      height="3.2rem"
      onClick={() => disconnectWallet()}
    >
      Market registration completed
    </Button>
  ) : (
    <Button
      id="redline_getBrowser"
      display={{ base: "flex", md: "flex" }}
      bg="#373f50"
      color="white"
      padding=".425rem 1rem"
      borderRadius="0.4375rem"
      borderColor="#373f50"
      fontSize="0.8125rem"
      _hover={{
        backgroundColor: "#1f293d",
        color: "white",
      }}
    >
      Connect to a wallet
    </Button>
  );
}

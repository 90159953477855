import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import AppContext from "./context/AppContex";
import useInitialState from "./hooks/useInitialState";
import Home from "./pages/Home";
import Mint from "./pages/Mint";
import Membership from "./pages/Membership";
import Gold from "./pages/Gold";
import Silver from "./pages/Silver";
import Bronze from "./pages/Bronze";
function App() {
  const initialState = useInitialState();

  return (
    <AppContext.Provider value={initialState}>
      <Layout>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="mint" element={<Mint />} />
            <Route path="membership" element={<Membership />} />
            <Route path="gold" element={<Gold />} />
            <Route path="silver" element={<Silver />} />
            <Route path="bronze" element={<Bronze />} />
          </Routes>
        </BrowserRouter>
      </Layout>
    </AppContext.Provider>
  );
}

export default App;

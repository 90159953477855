import { ReactNode } from "react";

export default function Footer() {
  return (
    <>
      <footer className="footer bg-darker">
        <div className="mt-n10 pt-10 bg-dark">
          <div className="container py-5"> </div>
        </div>
        <div className="container my-lg-4 my-3 py-2">
          <div className="d-flex flex-md-row flex-column-reverse align-items-center justify-content-md-between">
            <div className="fs-xs text-light opacity-50">
              &copy; RedNFT Marketplce by:
              <a
                className="text-light"
                href="https://redlineblockchain.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Redline Blockchain
              </a>
            </div>
            <div className="d-flex align-items-start mb-md-0 mb-3 mx-n1">
              <div className="px-1">
                <a
                  className="btn-market btn-apple bg-dark"
                  href="https://apps.apple.com/dk/app/red-wallet/id1607470682"
                >
                  <span className="btn-market-subtitle">Download on the</span>
                  <span className="btn-market-title">App Store</span>
                </a>
              </div>
              <div className="px-1">
                <a
                  className="btn-market btn-google bg-dark"
                  href="https://play.google.com/store/apps/details?id=com.redlineblockchain.wallet&hl=en_US&gl=US"
                >
                  <span className="btn-market-subtitle">Download on the</span>
                  <span className="btn-market-title">Google Play</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* 
      <div className="handheld-toolbar">
        <div className="d-table table-layout-fixed w-100">
          <a
            className="d-none handheld-toolbar-item"
            href="#vendor-sidebar"
            data-bs-toggle="offcanvas"
          >
            <span className="handheld-toolbar-icon">
              <i className="ci-sign-in" />
            </span>
            <span className="handheld-toolbar-label">Sidebar</span>
          </a>
          <a className="d-table-cell handheld-toolbar-item" href="explore.html">
            <span className="handheld-toolbar-icon">
              <i className="ci-filter-alt" />
            </span>
            <span className="handheld-toolbar-label explore-1">
              Explore NFTs
            </span>
          </a>
          <a
            className="d-table-cell handheld-toolbar-item"
            href="#signin-modal"
            data-bs-toggle="modal"
          >
            <span className="handheld-toolbar-icon">
              <i className="ci-user" />
            </span>
            <span className="handheld-toolbar-label">Account</span>
          </a>
          <a
            className="d-table-cell handheld-toolbar-item"
            href="javascript:void(0)"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            onClick="window.scrollTo(0, 0)"
          >
            <span className="handheld-toolbar-icon">
              <i className="ci-menu" />
            </span>
            <span className="handheld-toolbar-label">Menu</span>
          </a>
          <a
            className="d-table-cell handheld-toolbar-item"
            href="nft-connect-wallet.html"
          >
            <span className="handheld-toolbar-icon">
              <i className="ci-wallet" />
            </span>
            <span className="handheld-toolbar-label text-nowrap">
              Connect wallet
            </span>
          </a>
        </div>
      </div>
*/}
    </>
  );
}

import { ReactNode } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure
} from '@chakra-ui/react'
type Props = {
    isOpen: any;
    onClose: any;
};

export default function Disclaimer({ isOpen, onClose }: Props) {
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Disclaimer </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        The four corner icons, currently displaying a question mark, are generated once you click the "Mint" button. The icon arrangement and sequence are unique to your NFT card.
                        <br />
                        <strong>Access Tokens:</strong>
                        <br />
                        Access Tokens are exclusive only to REDVIP GOLD NFT holders, offer reserved access to Redline Blockchain's NFT Marketplace and perks and discounts into our entire ecosystem and services.
                        <br />
                        <br />
                        <strong>Admission tokens:</strong>
                        <br />
                        Admission Tokens allow holders to attend RedCon for three years with discounted rates.
                        <br /><br />
                        <strong>Gift Tokens:</strong>
                        <br />
                        Gift tokens, there are 3000 gift tokens. As the name implies, their owners will get gifts from the Redline Blockchain team. Afterward, Gift token holders will receive a minimum of 6 digital gifts from Redline Blockchain in a span of 3 years.
                        <br /><br />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='red' mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

import React, { useState, useEffect, useContext } from "react";
import Logo from "../assets/img/logo-red-nft-marketplace.png";
import ConnectButton from "./ConnectButton";
export default function Header(props) {
  const [isActive, setActive] = useState(false);
  // :: Sticky Header
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = () => {
    const header = document.querySelector(".navbar");
    const scrollTop = window.scrollY;
    scrollTop >= 10
      ? header.classList.add("navFixedNFT")
      : header.classList.remove("navFixedNFT");
  };
  return (
    <>
      <header className="navbar d-block navbar-sticky navbar-expand-lg navbar-light position-absolute w-100">
        <div className="container">
          <a
            className="navbar-brand d-none d-sm-block me-4 order-lg-1"
            href="/"
          >
            <img src={Logo} width="142" alt="RED NFT Marketplace" />
          </a>
          <a className="navbar-brand d-sm-none me-2 order-lg-1" href="/">
            <img src={Logo} width="74" alt="Cartzilla" />
          </a>
          <div className="navbar-toolbar d-flex align-items-center order-lg-3">
            <ConnectButton />
          </div>
        </div>
      </header>
    </>
  );
}

import { useState, useEffect } from "react";

export function  useWallet ()  {
  const [account, setAccount] = useState('');
  const [keyAddress, setkeyAddress] = useState();
  
  useEffect(() => {
    setAccount(localStorage.getItem('accountAddress'))  
    //setkeyAddress(JSON.parse(localStorage.getItem('keyAddress')) || [])  
  }, []);
  
  useEffect(() => {
    //accountAddress
    window.addEventListener('storage', () => {
        setAccount(localStorage.getItem('accountAddress'))  
        setkeyAddress(localStorage.getItem('keyAddress'))  
      });
  }, []);


  return {
    account,
    keyAddress
  };
};
